<template>
      
  <div>
    <div class="foots">
      <span class="textShare">分享:</span>
       
      <img src="../assets/img/weibo.png" alt="" @click="sinlang" />
      <img src="../assets/img/wechat.png" alt="" @click="haveCode" />
    </div>
    <div class="bd_weixin_popup" v-if="share_flag">
      <div
        id="bdshare_weixin_qrcode_dialog_bg"
        class="bd_weixin_popup_bg"
      ></div>
      <div class="bd_weixin_popup_head">
        <span class="share_title">分享到朋友圈</span>

        <div class="bd_weixin_popup_close" @click="shareFlag">
          <img src="../assets/pop/close.png" alt="" />
        </div>
      </div>
      <div id="qrcode"></div>
      <span class="share_footer">
        打开微信，点击底部的“发现”， <br />
        使用“扫一扫”即可将网页分享至朋友圈。
      </span>
    </div>

        
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    titles: String,
    images: String,
  },
  data() {
    return {
      num: 0,
      share_flag: false,
      number:0
    };
  },
  created() {},
  mounted() {
    // this.qrcode();
  },
  methods: {
    shareFlag(){
      this.share_flag = false
      this.number = 0
    },
    async qrcode() {
      await this.$nextTick(() => {
        var qrcode = new QRCode("qrcode", {
          text: window.location.href,
          width: 185,
          height: 185,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    haveCode() {
      this.number ++
      if(this.number >1)
      {

      }else{
      this.share_flag = true;
      this.qrcode();
      }
      
    },
    sinlang() {
      var href = window.location.href;
      var routeUrl =
        "https://service.weibo.com/share/share.php?title=" +
        this.titles +
        "&url=" +
        href +
        "&pic=" +
        this.images;
      window.open(routeUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.foots {
  text-align: right;
  max-width: 1400px;
  height: 114px;

  font-size: 20px;
  line-height: 30px;
  margin: 0 auto;
  padding-top: 47px;
  box-sizing: border-box;
  padding-bottom: 37px;
  padding-right: 9px;
}
.foots img {
  height: 30px;
  width: 30px;
  margin: 0 5px;
  float: right;
  /* margin-top: -25px; */
  /* margin-top: 75px; */
}
.bd_weixin_popup {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 10px;
  width: 280px !important;
  height: 340px !important;
  background: #ffffff;
  border: solid 1px #d8d8d8;
  z-index: 11001;
  font-size: 12px;
  margin-left: -140px;
  margin-top: -170px;
}
.share_title {
  float: left;
  font-weight: bold;
}
.bd_weixin_popup_head {
  position: relative;
  width: 100%;
  height: 20px;
}
.bd_weixin_popup_close {
  width: 20px;
  height: 20px;
  color: #999;
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 5px;
}
.bd_weixin_popup_close img {
  width: 9px;
  height: 9px;
  cursor: pointer;
}
#qrcode {
  width: 185px;
  margin: 0 auto;
  padding: 15px 10px;
}
#qrcode img {
  margin: 0 auto;
}
.share_footer {
  display: block;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
  color: #666;
}
.textShare{
  margin-right: 10px;
  color: #666666;
  font-family: PingFang SC;
}
</style>
