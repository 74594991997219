<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" class="action">
     <el-breadcrumb-item  :disabled="index == $store.state.menu.length-1" v-for="(item,index) in $store.state.menu" :key="index" :to="{ path: item.path }">{{ item.name }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
    props:{
        item:String
    },
    data() {
        return {
            erji:sessionStorage.getItem('erji')
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang="scss">
.action {
  display: block;
  max-width: 1400px;
  min-width: 1266px;
  margin: 0 auto;
  font-size: 16px;
  color: #1a1a1a;
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 60px;
}

</style>
<style >
  >>>.el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover {
    color: #606266;
    cursor: pointer;
}
  @media screen   and (max-width: 1439px) {
  .action{
    width: 1266px !important;
    max-width: 1266px;
  } 
}
</style>
